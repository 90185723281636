/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  RouteProps,
} from 'react-router-dom';
import { isLoading } from './store/agents/selectors';
import { useAuthenticationStatus } from './hooks/useAuthenticationStatus';
import { useAppDispatch, useAppSelector } from './store';
import { signOutHandler } from './utils/signOutHandler';
import { getUser } from './store/User/actions';
import { sendToast } from './utils/helpers';
import { setErrorObject } from './store/globalErrors/reducer';

// pages
// const Home = React.lazy(() => import('./pages/Home'));
const Modal = React.lazy(() => import('./components/Modal/Modal'));
const MapPage = React.lazy(() => import('./pages/MapPage'));
const OutboundLiveTransfer = React.lazy(
  () => import('./pages/OutboundLiveTransferPage'),
);

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  children: React.ReactNode;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isAuthenticated,
  children,
  ...rest
}) => <Route {...rest}>{isAuthenticated ? children : <></>}</Route>;

const App = () => {
  const dispatch = useAppDispatch();
  const [isAuthenticated, authLoading] = useAuthenticationStatus();
  const loading = useAppSelector((state) => isLoading(state));
  const { user } = useAppSelector((state) => state.users);
  const { errorObject } = useAppSelector((state) => state.globalErrors);

  useEffect(() => {
    if (errorObject) {
      sendToast(`${errorObject.code}: ${errorObject.message}`, {
        type: 'error',
        autoClose: false,
        closeOnClick: false,
      });
      dispatch(setErrorObject(null));
    }
  }, [errorObject]);

  useEffect(() => {
    if (!isAuthenticated && !authLoading) {
      Sentry.setUser(null);
      signOutHandler();
    }

    if (isAuthenticated && !authLoading && !user) {
      dispatch(getUser());
    }

    if (isAuthenticated && !authLoading && user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.username,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, authLoading, isAuthenticated]);

  const showPrivateRouts = !authLoading && isAuthenticated;
  return (
    <>
      <Router>
        <Switch>
          <PrivateRoute isAuthenticated={isAuthenticated} exact path="/">
            {showPrivateRouts ? (
              <Suspense
                fallback={<main className="h-screen bg-[var(--mode-bg)] " />}
              >
                <MapPage loading={loading} />
              </Suspense>
            ) : (
              <></>
            )}
          </PrivateRoute>
          <PrivateRoute isAuthenticated={isAuthenticated} path="/map">
            {showPrivateRouts ? (
              <Suspense
                fallback={<main className="h-screen bg-[var(--mode-bg)] " />}
              >
                <MapPage loading={loading} />
              </Suspense>
            ) : (
              <></>
            )}
          </PrivateRoute>
          <PrivateRoute isAuthenticated={isAuthenticated} path="/outbound">
            <Suspense
              fallback={<main className="h-screen bg-[var(--mode-bg)] " />}
            >
              <OutboundLiveTransfer />
            </Suspense>
          </PrivateRoute>
        </Switch>
      </Router>
      <Suspense>
        <Modal />
      </Suspense>
    </>
  );
};

export default App;
