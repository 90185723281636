import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';

export interface SLA {
  id: number;
  updated_at: string;
  created_at: string;
  name: string;
  market_volume: string; // Low, Mid, High
  seller_price_floor: number;
  seller_is_under: boolean;
  buyer_price_floor: number;
  buyer_is_under: boolean;
  number_of_agents: number;
  time_to_first_match: number;
  time_to_final_match: number;
}

export interface ErrorResponse {
  hasError: boolean;
  error: any;
}

export interface SLAProps {
  count: number;
  next: string;
  previous: string;
  results: SLA[];
}

interface SLARequestProps {
  page: number;
}

// SLA Requests
export const fetchSLARequests = createAsyncThunk<
  SLAProps | ErrorResponse,
  SLARequestProps
>('slas/fetchSLARequests', async (props) => {
  try {
    const response = await instance.get(`/deals/slas/?page=${props.page}`);
    return response.data;
  } catch (e) {
    const typedError: any = e;
    return { hasError: true, error: typedError.response.data };
  }
});

// Create
export const createSLA = createAsyncThunk<SLA | ErrorResponse, SLA>(
  'slas/createSLA',
  async (props) => {
    try {
      const response = await instance.post('/deals/sla/', props);
      return response.data;
    } catch (e) {
      const typedError: any = e;
      return { hasError: true, error: typedError.response.data };
    }
  },
);

export const patchSLA = createAsyncThunk<SLA | ErrorResponse, SLA>(
  'slas/patchSLA',
  async (props) => {
    try {
      const response = await instance.patch(`/deals/sla/${props.id}/`, props);
      return response.data;
    } catch (e) {
      const typedError: any = e;
      return { hasError: true, error: typedError.response.data };
    }
  },
);

export const deleteSLA = createAsyncThunk<any | ErrorResponse, number>(
  'slas/deleteSLA',
  async (id) => {
    try {
      const response = await instance.delete(`/deals/sla/${id}/`);
      return response.data;
    } catch (e) {
      const typedError: any = e;
      return { hasError: true, error: typedError.response.data };
    }
  },
);
