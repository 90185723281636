import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';
import instance from '../../axiosInstance';
import { handleErrorObject } from '../../utils/errors/handleErrorObject';
import { ErrorResource } from '../../types/error';

interface LocationObject {
  latitude: number | string;
  longitude: number | string;
}

export interface PresignedUrls {
  agents: string | null;
  form_submissions: string | null;
  /**
   * to determine the color of the marker
   * an array of id then color id then color
   */
  mapped_agent_data: string | null;
  transactions: string | null;
}
/// /
// Async Thunk Actions
/// /
export const getLocationResponse = createAsyncThunk<LocationObject, string>(
  'map/getLocationResponse',
  async (address, { rejectWithValue, signal, dispatch }) => {
    try {
      const response = await instance.get(
        `/locations/search/?q=${encodeURIComponent(address)}`,
        {
          signal,
        },
      );
      return response.data.data;
    } catch (_err) {
      const error = _err as AxiosError;
      handleErrorObject({
        error,
        dispatch,
        errorResource: ErrorResource.MAP_GET_LOCATION_RESPONSE,
      });
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getPresignedUrls = createAsyncThunk<PresignedUrls>(
  'map/getPresignedUrls',
  async (_, { rejectWithValue, signal, dispatch }) => {
    try {
      const response = await instance.get('/presigned-urls/', {
        signal,
      });
      return response.data;
    } catch (_err) {
      const error = _err as AxiosError;
      handleErrorObject({
        error,
        dispatch,
        errorResource: ErrorResource.MAP_GET_PRE_SIGNED_URLS,
      });
      return rejectWithValue(error?.response?.data);
    }
  },
);
