import { createSlice } from '@reduxjs/toolkit';
import { DealFlagList } from '../../interfaces/dealFlags';
import { DealList } from '../../interfaces/dealList';
import {
  Statistics,
  acknowledgeDealFlagById,
  fetchDeals,
  getDealFlagsByTerritory,
  getSearchListStatistics,
  getSearchListStatisticsByFilters,
  searchDealsByFilters,
  searchDealsById,
} from './actions';

interface DealsState {
  hasErrors: boolean;
  loading: boolean;
  dealList: DealList | null;
  dealFlags: DealFlagList | null;
  hasUpdatedFlag: boolean;
  filteredStatistics: Statistics | null;
  statistics: Statistics | null;
}

const initialState: DealsState = {
  hasErrors: false,
  loading: false,
  dealList: null,
  dealFlags: null,
  hasUpdatedFlag: false,
  filteredStatistics: null,
  statistics: null,
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    resetFilteredStats(state) {
      state.filteredStatistics = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch Customer Deals
    builder.addCase(fetchDeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeals.fulfilled, (state, { payload }) => {
      state.dealList = payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchDeals.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    // Search Deals By Id
    builder.addCase(searchDealsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchDealsById.fulfilled, (state, { payload }) => {
      state.dealList = payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(searchDealsById.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    // Search Deals By Filters
    builder.addCase(searchDealsByFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchDealsByFilters.fulfilled, (state, { payload }) => {
      state.dealList = payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(searchDealsByFilters.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    // GET Deal Flags By Filters
    builder.addCase(getDealFlagsByTerritory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDealFlagsByTerritory.fulfilled, (state, { payload }) => {
      state.dealFlags = payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(getDealFlagsByTerritory.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    // PATCH Deal Flags By ID
    builder.addCase(acknowledgeDealFlagById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(acknowledgeDealFlagById.fulfilled, (state) => {
      state.loading = false;
      state.hasUpdatedFlag = true;
      state.hasErrors = false;
    });
    builder.addCase(acknowledgeDealFlagById.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    // GET STATISTICS
    builder.addCase(getSearchListStatistics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSearchListStatistics.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.statistics = payload;
      state.hasErrors = false;
    });
    builder.addCase(getSearchListStatistics.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    // STATISTICS BY FILTERS
    builder.addCase(getSearchListStatisticsByFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSearchListStatisticsByFilters.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.filteredStatistics = payload;
        state.hasErrors = false;
      },
    );
    builder.addCase(getSearchListStatisticsByFilters.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
  },
});

export const { resetFilteredStats } = dealsSlice.actions;
// Reducer
export default dealsSlice.reducer;
