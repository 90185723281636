import { EmptyObject, createSlice } from '@reduxjs/toolkit';
import {
  fetchSearchById,
  patchSearchById,
  endSearchActivity,
  patchDealById,
  postInvitation,
  cancelInvitationById,
} from './actions';
import { getTemplates } from '../templates/actions';
import Search, { SearchAgentMetricsMap } from '../../interfaces/search';
import Invitation, {
  AgentIdToInvitationPayloadMap,
  AgentIdToInvitationCreationErrorMap,
  InvitationCreationError,
  InvitationLiveUpdate,
  SearchAgentInvitationMap,
} from '../../interfaces/invitation';
import {
  makeSearchAgentInvitationMapFromInvitationList,
  makeSearchAgentMetricsMapFromSearch,
} from '../../utils/helpers';
import {
  getDefaultEmailTemplateForDeal,
  getDefaultSmsTemplateForDeal,
  renderDjangoTemplate,
  getContextForDeal,
} from '../../utils/templateHelpers';
import { ContextType } from '../../enums/ContextType';
import { SearchAgent } from '../../interfaces/agent';

interface SearchesState {
  hasErrors?: boolean | undefined;
  error?: string | null | undefined;
  loading?: boolean | undefined;
  search: Search | any;
  showSearch: boolean;
  refreshSearch: boolean;
  stagedAgents: number[];
  listingAddress: string;
  lastListingAddress: string;
  successfullyEndedActivity: boolean;
  patchErrors: any;
  searchAgentMetricsMap: SearchAgentMetricsMap;
  searchAgentInvitationMap: SearchAgentInvitationMap;
  stagedInvitationPayloads: AgentIdToInvitationPayloadMap;
  invitationCreationErrors: AgentIdToInvitationCreationErrorMap;
  latestLiveUpdate: number;
  templates: any;
}

const initialState: SearchesState = {
  hasErrors: false,
  error: null,
  loading: false,
  search: {} as Search,
  showSearch: false,
  refreshSearch: false,
  stagedAgents: [],
  listingAddress: '',
  lastListingAddress: '',
  successfullyEndedActivity: false,
  patchErrors: null,
  searchAgentMetricsMap: {} as SearchAgentMetricsMap,
  searchAgentInvitationMap: {} as SearchAgentInvitationMap,
  stagedInvitationPayloads: {} as AgentIdToInvitationPayloadMap,
  invitationCreationErrors: {} as AgentIdToInvitationCreationErrorMap,
  latestLiveUpdate: 0,
  templates: null,
};

const searchesSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {
    resetEndActivityState(state) {
      state.successfullyEndedActivity = false;
    },
    clearSearchId(state) {
      state.search = {} as Search;
      state.searchAgentInvitationMap = {};
      state.hasErrors = false;
      state.showSearch = false;
      state.refreshSearch = false;
      state.successfullyEndedActivity = false;
    },
    updateShowSearch(state, action) {
      state.refreshSearch = action.payload;
    },
    stageAgentOnSearch(state, action) {
      state.stagedAgents = [...state.stagedAgents, action.payload];
    },
    unstageAgentOnSearch(state, action) {
      state.stagedAgents = state.stagedAgents.filter(
        (stagedAgentId) => stagedAgentId !== action.payload,
      );
    },
    stageInvitationPayload(state, action) {
      state.stagedInvitationPayloads = {
        ...state.stagedInvitationPayloads,
        [action.payload.agent]: action.payload,
      };
    },
    unstageInvitationPayload(state, action) {
      const newStagedInvitationPayloads = { ...state.stagedInvitationPayloads };
      delete newStagedInvitationPayloads[action.payload];
      state.stagedInvitationPayloads = newStagedInvitationPayloads;

      const newInvitationCreationErrors = { ...state.invitationCreationErrors };
      delete newInvitationCreationErrors[action.payload];
      state.invitationCreationErrors = newInvitationCreationErrors;
    },
  },
  extraReducers: (builder) => {
    // Fetch Customer Deals
    builder.addCase(fetchSearchById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchById.fulfilled, (state, { payload }) => {
      const { templates } = state;

      state.search = payload;
      state.stagedAgents = payload.opted_in_agents.map(
        (searchAgent: SearchAgent) => searchAgent.agent.vid,
      );
      const newSearchAgentInvitationMap =
        makeSearchAgentInvitationMapFromInvitationList(payload.invitations);
      state.searchAgentInvitationMap = newSearchAgentInvitationMap;
      state.searchAgentMetricsMap =
        makeSearchAgentMetricsMapFromSearch(payload);
      state.showSearch = true;
      state.listingAddress = payload.deal?.location
        ? payload.deal?.location?.address
        : '';
      state.loading = false;
      state.hasErrors = false;
      const defaultEmailTemplate = getDefaultEmailTemplateForDeal(
        payload.deal,
        templates,
      );
      const defaultSmsTemplate = getDefaultSmsTemplateForDeal(
        payload.deal,
        templates,
      );
      const newStagedInvitationPayloads = payload.opted_in_agents.reduce(
        (acc, searchAgent: SearchAgent) => {
          if (
            searchAgent.delay !== null &&
            !newSearchAgentInvitationMap[searchAgent.agent.vid]
          ) {
            const customAgentCommission =
              payload.deal.commission_model_name === 'Clever Cash Back (0.5%)'
                ? 40
                : null;
            acc[searchAgent.agent.vid] = {
              agent: searchAgent.agent.vid,
              delay_time: searchAgent.delay,
              search: payload.id,
              email_intro_message: renderDjangoTemplate(
                defaultEmailTemplate.value.template,
                getContextForDeal(
                  ContextType.email,
                  payload.deal,
                  templates,
                  searchAgent.agent,
                ),
              ),
              sms_intro_message: renderDjangoTemplate(
                defaultSmsTemplate.value.template,
                getContextForDeal(
                  ContextType.sms,
                  payload.deal,
                  templates,
                  searchAgent.agent,
                ),
              ),
              custom_agent_commission: customAgentCommission,
            };
          }
          return acc;
        },
        {} as AgentIdToInvitationPayloadMap,
      );
      state.stagedInvitationPayloads = newStagedInvitationPayloads;
    });
    builder.addCase(fetchSearchById.rejected, (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true; // TODO: add errors
      state.patchErrors = (payload as any)?.errorData || {};
    });
    // Update Customer Deals
    builder.addCase(patchSearchById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchSearchById.fulfilled, (state, { payload }) => {
      state.loading = false;
      const { templates } = state;
      const defaultPlaybook = state.search.default_playbook || undefined;
      state.search = {
        ...payload,
        default_playbook: defaultPlaybook,
      };
      const newSearchAgentInvitationMap =
        makeSearchAgentInvitationMapFromInvitationList(payload.invitations);
      state.searchAgentInvitationMap = newSearchAgentInvitationMap;
      state.searchAgentMetricsMap =
        makeSearchAgentMetricsMapFromSearch(payload);
      state.stagedAgents = payload.opted_in_agents.map(
        (searchAgent: SearchAgent) => searchAgent.agent.vid,
      );
      state.hasErrors = false;
      state.patchErrors = null;
      const defaultEmailTemplate = getDefaultEmailTemplateForDeal(
        payload.deal,
        templates,
      );
      const defaultSmsTemplate = getDefaultSmsTemplateForDeal(
        payload.deal,
        templates,
      );
      const newStagedInvitationPayloads = payload.opted_in_agents.reduce(
        (acc: AgentIdToInvitationPayloadMap, searchAgent: SearchAgent) => {
          if (
            searchAgent.delay !== null &&
            !newSearchAgentInvitationMap[searchAgent.agent.vid]
          ) {
            const customAgentCommission =
              payload.deal.commission_model_name === 'Clever Cash Back (0.5%)'
                ? 40
                : null;
            acc[searchAgent.agent.vid] = {
              agent: searchAgent.agent.vid,
              delay_time: searchAgent.delay,
              search: payload.id,
              email_intro_message: renderDjangoTemplate(
                defaultEmailTemplate.value.template,
                getContextForDeal(
                  ContextType.email,
                  payload.deal,
                  templates,
                  searchAgent.agent,
                ),
              ),
              sms_intro_message: renderDjangoTemplate(
                defaultSmsTemplate.value.template,
                getContextForDeal(
                  ContextType.sms,
                  payload.deal,
                  templates,
                  searchAgent.agent,
                ),
              ),
              custom_agent_commission: customAgentCommission,
            };
          }
          return acc;
        },
        {} as AgentIdToInvitationPayloadMap,
      );
      state.stagedInvitationPayloads = newStagedInvitationPayloads;
    });
    builder.addCase(patchSearchById.rejected, (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.patchErrors = (payload as any)?.errorData || {};
    });
    // Patch Deal on search
    builder.addCase(patchDealById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchDealById.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.error =
          'There was an error updating the search/deal. Please check your fields and try again.';
      } else {
        state.search = {
          ...state.search,
          deal: { ...state.search.deal, ...payload },
        };
        state.hasErrors = false;
        state.error = null;
      }
    });
    builder.addCase(patchDealById.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true; // TODO: add errors
    });

    // End Search Activity
    builder.addCase(endSearchActivity.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(endSearchActivity.fulfilled, (state) => {
      state.loading = false;
      state.successfullyEndedActivity = true;
      // Clear Search
      state.search = {} as Search;
      state.searchAgentInvitationMap = {} as SearchAgentInvitationMap;
      state.stagedAgents = [] as number[];
    });
    builder.addCase(endSearchActivity.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });

    // Invitations
    builder.addCase(postInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postInvitation.fulfilled, (state, { payload }) => {
      state.loading = false;
      const invitation = payload as Invitation;
      const agentId = invitation.agent;
      state.searchAgentInvitationMap = {
        ...state.searchAgentInvitationMap,
        [agentId]: invitation,
      };

      const newInvitationPayloads = { ...state.stagedInvitationPayloads };
      delete newInvitationPayloads[agentId];
      state.stagedInvitationPayloads = newInvitationPayloads;

      const exists =
        state.search.invitations.filter(
          (invitation) => invitation.id === payload.id,
        ).length > 0;
      if (!exists) {
        state.search.invitations = [...state.search.invitations, invitation];
      }

      if (state.invitationCreationErrors?.[agentId]) {
        const newInvitationCreationErrors = {
          ...state.invitationCreationErrors,
        };
        delete newInvitationCreationErrors[agentId];
        state.invitationCreationErrors = newInvitationCreationErrors;
      }
    });
    builder.addCase(postInvitation.rejected, (state, action) => {
      state.loading = false;
      const invitationError: InvitationCreationError =
        action.payload as InvitationCreationError;
      const { agentId } = invitationError;
      const newInvitationCreationErrors = { ...state.invitationCreationErrors };
      newInvitationCreationErrors[agentId] = invitationError;
      state.invitationCreationErrors = newInvitationCreationErrors;
    });
    builder.addCase(cancelInvitationById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(cancelInvitationById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;

      const { invitationId, agentId } = payload;
      const newInvitations = state.search.invitations.filter(
        (invitation) => invitation.id !== invitationId,
      );
      state.search.invitations = newInvitations;

      const newSearchAgentInvitationMap = { ...state.searchAgentInvitationMap };
      delete newSearchAgentInvitationMap[agentId];
      state.searchAgentInvitationMap = newSearchAgentInvitationMap;
    });
    builder.addCase(cancelInvitationById.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    builder.addCase(getTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload;
    });
  },
});

export const {
  clearSearchId,
  updateShowSearch,
  resetEndActivityState,
  stageAgentOnSearch,
  unstageAgentOnSearch,
  stageInvitationPayload,
  unstageInvitationPayload,
} = searchesSlice.actions;

// Reducer
export default searchesSlice.reducer;
