import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { CommunicationNote } from '../../interfaces/communication';
import { Market } from '../../interfaces/market';

export interface Bounds {
  nex: number;
  ney: number;
  swx: number;
  swy: number;
}

export interface MarketProps {
  name: string;
  description: string;
  polygon: any;
  aoo: boolean;
  number_of_agents?: number;
}

export interface UpdateMarketProps {
  name?: string;
  description?: string;
  id: number;
  number_of_agents?: number;
  aoo?: boolean;
}

/// /
// Markets
/// /
export const fetchMarketNotesFromBounds = createAsyncThunk<any, Bounds>(
  'marketNotes/fetchMarketNotesFromBounds',
  async (bounds) => {
    const response = await instance.get(
      `/deals/markets-in-bounds/?nex=${bounds.nex}&ney=${bounds.ney}&swx=${bounds.swx}&swy=${bounds.swy}`,
    );
    return response.data;
  },
);

export const createNewMarket = createAsyncThunk<Market, MarketProps>(
  'marketNotes/createNewMarket',
  async (market) => {
    const response = await instance.post(`/deals/market/`, {
      name: market.name,
      description: market.description,
      polygon: market.polygon,
      bounty: market.aoo,
      number_of_agents: market.number_of_agents,
    });
    return response.data;
  },
);

export const fetchMarketById = createAsyncThunk<Market, number>(
  'marketNotes/fetchMarketById',
  async (id) => {
    const response = await instance.get(`/deals/market/${id}/`);
    return response.data;
  },
);

export const updateMarketById = createAsyncThunk<Market, UpdateMarketProps>(
  'marketNotes/updateMarketById',
  async (props) => {
    const response = await instance.put(`/deals/market/${props.id}/`, {
      ...props,
      bounty: props.aoo,
    });
    return response.data;
  },
);

export const deleteMarketById = createAsyncThunk<Market, number>(
  'marketNotes/deleteMarketById',
  async (id) => {
    const response = await instance.delete(`/deals/market/${id}/`);
    return response.data;
  },
);
/// /
// Market Notes
/// /

export const createCommunicationNote = createAsyncThunk<CommunicationNote, any>(
  'marketNotes/createCommunicationNote',
  async (noteObj) => {
    const { marketId, note } = noteObj;

    const response = await instance.post(`/communications/note/`, {
      note,
      market: marketId,
    });
    return response.data;
  },
);

export const updateCommunicationNote = createAsyncThunk<CommunicationNote, any>(
  'marketNotes/updateCommunicationNote',
  async (noteData) => {
    const noteId = noteData.id;
    const { note } = noteData;
    const response = await instance.patch(`/communications/note/${noteId}/`, {
      note,
    });
    return response.data;
  },
);

export const deleteCommunicationNote = createAsyncThunk<
  CommunicationNote,
  number
>('marketNotes/deleteCommunicationNote', async (noteId) => {
  const response = await instance.delete(`/communications/note/${noteId}/`);
  return response.data;
});
