import { AxiosRequestConfig } from 'axios';
import instance from '../../axiosInstance';

import { CONFIG } from '../../config/constant';
import { AgentCreationSchemaFormValues } from '../../components/AgentCreation/utils/agentCreationSchema';

export type CreatedAgent = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  agent_profile_link: string;
  agent_conversion_source: string;
  lead_jail_reason: string;
  brokerage_brand: string | null;
  home_base_location: string | null;
  is_in_lead_jail: boolean;
};

type CreateProps = {
  data: AgentCreationSchemaFormValues;
  config?: AxiosRequestConfig;
};

const create = async ({ data, config }: CreateProps) =>
  instance.post<CreatedAgent>(CONFIG.endpoints.agents.create, data, config);

const agentsService = {
  create,
};

export default agentsService;
