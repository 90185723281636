import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ConfigState {
  menuOpen: boolean;
}

const initialState: ConfigState = {
  menuOpen: true,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    toggleMenuState: (state) => {
      state.menuOpen = !state.menuOpen;
    },
  },
});

export const { toggleMenuState } = configSlice.actions;

// Reducer
export default configSlice.reducer;
