import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';

interface Bounds {
  nex: number;
  ney: number;
  swx: number;
  swy: number;
}

export interface SourcingTerritoryRequestProps {
  name: string;
  difficulty: string; // Low, Medium, High
  polygon: any;
}

export interface UpdateSourcingTerritoryProps {
  name?: string;
  difficulty?: string;
  id: number;
}

export interface SourcingTerritory {
  id: number;
  updated_at: string;
  created_at: string;
  difficulty: string;
  name: string;
  polygon: any;
}

/// /
// Markets
/// /
export const fetchSourcingTerritoriesFromBounds = createAsyncThunk<any, Bounds>(
  'sourcingTerritories/fetchSourcingTerritoriesFromBounds',
  async (bounds) => {
    const response = await instance.get(
      `/deals/sourcing-territories-in-bounds/?nex=${bounds.nex}&ney=${bounds.ney}&swx=${bounds.swx}&swy=${bounds.swy}`,
    );
    return response.data;
  },
);

export const createNewSourcingTerritory = createAsyncThunk<
  SourcingTerritory,
  SourcingTerritoryRequestProps
>(
  'sourcingTerritories/createNewSourcingTerritory',
  async (sourcingTerritory) => {
    const response = await instance.post(`/deals/sourcing-territory/`, {
      name: sourcingTerritory.name,
      difficulty: sourcingTerritory.difficulty,
      polygon: sourcingTerritory.polygon,
    });
    return response.data;
  },
);

export const fetchSourcingTerritoryById = createAsyncThunk<
  SourcingTerritory,
  number
>('sourcingTerritories/fetchSourcingTerritoryById', async (id) => {
  const response = await instance.get(`/deals/sourcing-territory/${id}/`);
  return response.data;
});

export const updateSourcingTerritoryById = createAsyncThunk<
  SourcingTerritory,
  UpdateSourcingTerritoryProps
>('sourcingTerritories/updateSourcingTerritoryById', async (props) => {
  const response = await instance.put(
    `/deals/sourcing-territory/${props.id}/`,
    {
      ...props,
    },
  );
  return response.data;
});

export const deleteSourcingTerritoryById = createAsyncThunk<
  SourcingTerritory,
  number
>('sourcingTerritories/deleteSourcingTerritoryById', async (id) => {
  const response = await instance.delete(`/deals/sourcing-territory/${id}/`);
  return response.data;
});
