/* eslint-disable promise/no-return-wrap */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable promise/always-return */
/* eslint-disable no-param-reassign */
/* eslint-disable promise/catch-or-return */
import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { captureException } from '@sentry/browser';
import { signOutHandler } from './utils/signOutHandler';
import { baseURL } from './config/constant';

const instance = axios.create({
  baseURL,
  // avoid timeout on large requests with slow connections
  timeout: 0,
});

instance.interceptors.request.use(
  async (config) =>
    new Promise((resolve) => {
      Auth.currentSession()
        .then((session) => {
          const idTokenExpire = session.getAccessToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res) => {
              res.refreshSession(
                refreshToken,
                (
                  err,
                  data: {
                    getAccessToken: () => {
                      ();
                      new ();
                      getJwtToken: { (): any; new (): any };
                    };
                  },
                ) => {
                  if (err) {
                    captureException(err);
                    Auth.signOut().then(() => {
                      signOutHandler();
                    });
                  } else {
                    if (config?.headers) {
                      config.headers.Authorization = `Bearer ${data
                        .getAccessToken()
                        .getJwtToken()}`;
                    }
                    resolve(config);
                  }
                },
              );
            });
          } else {
            if (config?.headers) {
              config.headers.Authorization = `Bearer ${session
                .getAccessToken()
                .getJwtToken()}`;
            }
            resolve(config);
          }
        })
        .catch((e) => {
          captureException(e);
          Auth.signOut().then(() => {
            signOutHandler();
          });
          resolve(config);
        });
    }),
);

export default instance;
