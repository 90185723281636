import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { fetchFEOffersAgentEligibilityAMP } from './actions';
import { FeatureFlag, FeatureFlagName } from '../../interfaces/FeatureFlag';

interface FeatureFlagsState {
  loading: boolean;
  success: boolean;
  errorMessage: string | undefined;
  featureFlags: FeatureFlag[];
}

const initialState: FeatureFlagsState = {
  success: false,
  loading: false,
  errorMessage: '',
  featureFlags: [],
};

const featureFlags = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchFEOffersAgentEligibilityAMP.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.success = true;

        const index = state.featureFlags.findIndex(
          (el) => el.name === FeatureFlagName.fe_offers_agent_eligibility_amp,
        );

        if (index > -1) {
          state.featureFlags[index] = payload;
        } else {
          state.featureFlags.push(payload);
        }

        state.errorMessage = undefined;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchFEOffersAgentEligibilityAMP.pending),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(fetchFEOffersAgentEligibilityAMP.rejected),
      (state, { payload }) => ({
        ...state,
        loading: false,
        success: false,
        errorMessage: payload,
      }),
    );
  },
});

export default featureFlags.reducer;
