import { createSlice } from '@reduxjs/toolkit';
import Agent from '../../interfaces/agent';
import { Market } from '../../interfaces/market';
import {
  createCommunicationNote,
  createNewMarket,
  deleteCommunicationNote,
  deleteMarketById,
  fetchMarketById,
  fetchMarketNotesFromBounds,
  updateCommunicationNote,
  updateMarketById,
} from './actions';
import { CommunicationNote } from '../../interfaces/communication';

interface MarketNotesState {
  hasErrors: boolean;
  loading: boolean;
  agent: Agent | null;
  noteId: number | null;
  openCreateMarketModal: boolean;
  markets: Market[] | null;
  currentEditingMarket: Market | null;
  currentEditingMarketNote: CommunicationNote | null;
  successfullyCreated: boolean;
  successfullyDeleted: boolean;
  successfullyUpdated: boolean;
  successfullyRecieved: boolean;
  successfullyCreatedNote: boolean;
  successfullyDeletedMarket: boolean;
  polygon: any;
  showAllMarkets: boolean;
}

const initialState: MarketNotesState = {
  hasErrors: false,
  loading: false,
  agent: null,
  noteId: null,
  openCreateMarketModal: false,
  markets: null,
  currentEditingMarket: null,
  currentEditingMarketNote: null,
  successfullyCreated: false,
  successfullyDeleted: false,
  successfullyUpdated: false,
  successfullyRecieved: false,
  successfullyCreatedNote: false,
  successfullyDeletedMarket: false,
  polygon: null,
  showAllMarkets: false,
};

const marketNotesSlice = createSlice({
  name: 'marketNotes',
  initialState,
  reducers: {
    setDisplayAllMarkets(state, action) {
      state.showAllMarkets = action.payload;
    },
    setCurrentPolygon(state, action) {
      state.polygon = action.payload;
    },
    setCurrentEditingMarket(state, action) {
      state.currentEditingMarket = action.payload;
    },
    setCurrentlyEditingNote(state, action) {
      state.currentEditingMarketNote = action.payload;
    },
    openCreateMarketModal(state) {
      state.openCreateMarketModal = true;
    },
    closeCreateMarketModal(state) {
      state.openCreateMarketModal = false;
    },
    resetSuccessStates(state) {
      state.successfullyCreated = false;
      state.successfullyDeleted = false;
      state.successfullyUpdated = false;
      state.successfullyRecieved = false;
      state.successfullyCreatedNote = false;
      state.successfullyDeletedMarket = false;
    },
  },
  extraReducers: (builder) => {
    // Async market notes fetch
    builder.addCase(fetchMarketNotesFromBounds.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchMarketNotesFromBounds.fulfilled,
      (state, { payload }) => {
        state.markets = payload.data;
        state.loading = false;
      },
    );
    builder.addCase(fetchMarketNotesFromBounds.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    builder.addCase(createNewMarket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewMarket.fulfilled, (state, { payload }) => {
      state.successfullyCreated = true;
      state.currentEditingMarket = { ...payload, aoo: payload.bounty };
      state.loading = false;
    });
    builder.addCase(createNewMarket.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Delete
    builder.addCase(deleteCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCommunicationNote.fulfilled, (state) => {
      state.successfullyDeleted = true;
      state.loading = false;
    });
    builder.addCase(deleteCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Update
    builder.addCase(updateCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCommunicationNote.fulfilled, (state) => {
      state.successfullyUpdated = true;
      state.loading = false;
    });
    builder.addCase(updateCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Create
    builder.addCase(createCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCommunicationNote.fulfilled, (state) => {
      state.successfullyCreated = true;
      state.loading = false;
    });
    builder.addCase(createCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Fetch market by id
    builder.addCase(fetchMarketById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMarketById.fulfilled, (state, { payload }) => {
      state.currentEditingMarket = { ...payload, aoo: payload.bounty };
      state.loading = false;
    });
    builder.addCase(fetchMarketById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Update market by id
    builder.addCase(updateMarketById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMarketById.fulfilled, (state, { payload }) => {
      state.currentEditingMarket = { ...payload, aoo: payload.bounty };
      state.loading = false;
    });
    builder.addCase(updateMarketById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Delete market by id
    builder.addCase(deleteMarketById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMarketById.fulfilled, (state) => {
      state.successfullyDeletedMarket = true;
      state.loading = false;
    });
    builder.addCase(deleteMarketById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
  },
});

export const {
  openCreateMarketModal,
  closeCreateMarketModal,
  setCurrentlyEditingNote,
  setCurrentPolygon,
  resetSuccessStates,
  setCurrentEditingMarket,
  setDisplayAllMarkets,
} = marketNotesSlice.actions;

// Reducer
export default marketNotesSlice.reducer;
